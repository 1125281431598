/* Como para estilizar o elemento step não podemos passar outra classe se não, esta classe será aplicada nos dois botões.
passamos a referencia das classes presentes no element */

.introjs-button.introjs-nextbutton {
  border: 1px solid transparent !important;
  background-color: #4299E1 !important;
  color: #fff !important;
  text-shadow: 1px 1px 0 #000 !important;
}

.introjs-button:focus {
  box-shadow: 0 0 0 0.2rem rgb(144 205 244 / 50%) !important;
}


.introjs-button.introjs-nextbutton:hover {
  background-color: #3182CE !important;
}

.introjs-button.introjs-prevbutton {
  background-color: transparent !important;
  color: #3182CE!important;
  border: 1px solid #4299E0 !important;
  text-shadow: none !important;
}

.introjs-button.introjs-prevbutton:hover {
  background-color: #ebf8ff !important;
}
